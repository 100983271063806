import extend from 'extend2';

const constants = require('./constants');

// 环境配置
// const envConfig = { prod, dev,test,stage }[defaultConfig.env];
const envConfig = {
    api: {
        timeout: 360 * 1000,
        host: window.location.origin
    }
};

// 常量配置
const constantConfig = {
    constants
};

// 配置统一采用 CommonJS 规范导出
// 避免导出对象中出现 default 字段
// 配置优先级：常量配置 > 环境配置 > 默认配置
// CommonJS 规范
// module.exports = extend(true, defaultConfig, envConfig, constantConfig);

// ES6 规范
export default extend(true, envConfig, constantConfig);