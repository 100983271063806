import Cookies from 'js-cookie'

// const TokenKey = 'vue_admin_template_token'
const TokenKey = 'yuxidaohang'
const Fnickname = 'Fnickname'


export function getToken() {
  // console.log(Cookies.get(TokenKey))
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  console.log('执行注销token')
  return Cookies.remove(TokenKey)
}

export function getNickname() {
  return Cookies.get(Fnickname)
}

export function setNickname(nickname) {
  return Cookies.set(Fnickname, nickname)
}

export function removeNickname() {
  console.log('删除nickname')
  return Cookies.remove(Fnickname)
}