import api from '@/api/api'

const soul = {
    getOneSoul(params) {
        //console.log(params)
        return api.get("/api/v2/soul",params);
    },
    addSoul(body){
        return api.postBody("/api/v2/soul",body);
    },
}

export default soul