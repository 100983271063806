module.exports =  {
    button: {
        login: "登录",
    },
    form: {
        username: '用户名',
        password: '密码'
    },
    title1: '添加心灵鸡汤',
    title2: '添加链接',
    title3: '编辑链接'
}