module.exports =  {
    button: {
        login: "Login",
    },
    form: {
        username: 'Username',
        password: 'Password'
    },
    title1: '添加心灵鸡汤',
    title2: '添加链接',
    title3: '编辑链接'
}