import api from '@/api/api'

const links = {
    GetLinks(params) {
        return api.get("/api/v2/link", params);
    },
    addLink(params) {
        return api.postBody("/api/v2/link", params);
    },
    updateLink(linkid, params) {
        return api.putBody("/api/v2/link/" + linkid, params);
    }
}
export default links