import api from '@/api/api'

const backend = {
    getBackendImage(params) {
        //console.log(params)
        return api.get("/api/v2/backendimage",params);
    },
    updateImage(body){
        return api.putBody("/api/v2/backendimage",body);
    },
}

export default backend