<template>
  <div id="searchID">
    <a-form-model method="get" target="_blank" :action="scdata.url" id="search-component">
        <a-form-model-item>
            <a-input placeholder="请输入搜索内容哦" :name="scdata.key" v-model="sctext" @pressEnter="onSearch">
                <!-- 搜索网站图标以及更换搜索网站的下拉菜单-->
                <a-dropdown slot="prefix" placement="bottomCenter">
                    <span class="el-dropdown-link">
                        <img :src="scdata.icon" alt="scdata.title" style="width: 20px;height: 20px;" />
                        <a-icon type="down" style="margin: 0px 0px 0px 6px;" />
                    </span>
                    <a-menu slot="overlay">
                      <a-menu-item
                        v-for="each_type in sctypelist"
                        :key="each_type"
                        :command="each_type"
                        @click="clickItem(each_type)"
                      >
                        <img :src="$store.state.searchList[each_type].icon" :alt="each_type"  style="width: 20px;height: 20px;" />
                        {{ $store.state.searchList[each_type].title }}
                      </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <a-icon slot="suffix"  type="search" style="color: rgba(0,0,0,.45)" @click="onSearch" />
            </a-input>
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>

export default {
  name: 'pageSearch',
  components: { },
  props: {
    pageHeight: {
      type: Number,
      required: true,
      default: 0
    },
    pageWidth: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      // 从store里面读取数据并使用
      sctype: '',
      scdata: {},
      sctypelist: [],
      sctext: '',
      trigger: ['click', 'hover']
    }
  },
  created() {
    // 设置默认值
    var last_type = sessionStorage.getItem('last_type')
    var default_type = last_type
        ? last_type
        : this.$store.getters.searchTypes[0]
    this.sctype = default_type
    this.sctypelist = this.$store.getters.searchTypes
    this.scdata = this.$store.state.searchList[default_type]
  },
  mounted() {
    // console.log('搜索框')
    if (this.pageHeight < 600) {
        document.getElementById('searchID').style.marginTop = '10px'
    } else if (this.pageHeight >= 600 & this.pageHeight < 900) {
        document.getElementById('searchID').style.marginTop = '50px'
    } else if (this.pageHeight > 900) {
        document.getElementById('searchID').style.marginTop = '100px'
    }
  },
  updated() {
  },
  methods: {
    clickItem(each_type) {
      console.log(each_type)
      this.sctype = each_type;
      sessionStorage.setItem("last_type", each_type);
      this.scdata = this.$store.state.searchList[each_type];
    },
    // 选择表单变化的时候同步数据，同时添加到session
    changedata: function(command) {
        this.sctype = command;
        sessionStorage.setItem("last_type", command);
        this.scdata = this.$store.state.searchList[command];
    },
    onSearch() {
      console.log('开始搜索啦啊啊')
      console.log(this.scdata)
      window.open(this.scdata.url + '?' + this.scdata.key + '=' + this.sctext)
    }
  },
  computed: {
  }
}
</script>
<style lang="less" scoped>
.soula{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  /* 文本/Text-5 */
  color: #1D2129;
  margin: 0px 0px 0px 8px;
}
/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 54px;
}
</style>
