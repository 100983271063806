// import Vue from 'vue'
import axios from 'axios'
import config from '@/config';
import { default as message } from 'ant-design-vue/lib/message';
import { getToken } from '../utils/auth.js'

// import {Message} from "element-ui";

// create an axios instance   创建axios实例
const service = axios.create({
    // baseURL: "/api", // api 的 base_url
    // baseURL: config.api.host, // api 的 base_url
    // baseURL: config.api.host, // api 的 base_url
    timeout: config.api.timeout ? config.api.timeout : '10000', // request timeout  设置请求超时时间
    changeOrigin: true, //是否跨域
    responseType: "json",
    withCredentials: true, // 是否允许带cookie这些
    headers: {
        "Content-Type": "application/json",
    }
})

//添加请求拦截器
service.interceptors.request.use(
    function(config) {
        //console.log(localStorage.token)
        // config.headers['token'] = localStorage.token;
        config.headers['SessionToken'] = getToken()
        return config;
    },
    function(error) {
        return Promise.reject(error);
    });

//添加响应拦截器
service.interceptors.response.use(function(response) {
    //对响应数据做些事
    return response;
}, function(error) {
    //console.log(error)
    if (error && error.response) {
        if (error.response.status) {
            switch (error.response.status) {
                case 302:
                    console.log(error.response)
                    break;
                case 400:
                    message.error(error.response.data.detail)
                    break;
                case 401:
                    //console.log(error.response.data.data)
                    window.location.href = error.response.data.data
                    break;
                case 403:  // 返回用户not active权限提示
                    message.error(error.response.data.message)
                    break;
                case 404:
                    message.error(error.response.data.detail)
                    break;
                case 500:
                    message.error(error.response.data.detail)
                    break;
                default:
                    message.error("出错了")
                    break;
            }
            //请求错误时做些事
            return Promise.reject(error);
        }
    } else {
        message.error("啊噢，后端不小心崩溃了。")
        return Promise.reject(error);
    }
});

export default service