<template>
  <div class="linksdiv" id="linksID">
    <div v-for="group in linkGroups" :key="group.fid" :value="group.fid">
        <a-row class="oneGroup">
            <a-col :span="4">
                {{group.fname}}
            </a-col>
            <a-col :span="20">
                <div class="oneGroupLinks">
                  <div class="oneLink" v-for="link in group.links" :key="link.fid" :value="link.fid" @click="openNewPage(link.flink)">
                    <div>
                      <img :src="link.icourl"  style="width: 24px;height: 24px;margin: 0px 6px 0px 0px;" />
                      {{link.fname}}
                      <a-icon type="edit"  v-show="editModal" @click="openEditModal(link)"/>
                    </div>
                  </div>
                </div>
            </a-col>
        </a-row>
    </div>
    <!--添加链接模态框-->
    <a-modal
      :title="$t('title3')"
      :visible="editLinkModalShow"
      @ok="editLink"
      @cancel="handleCancel"
      >
        <a-form-model :model="linkForm" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="组名">
              <a-select default-value="lucy" style="width: 120px" v-model="linkForm.fgid">
                <a-select-option v-for="group in linkGroups" :key="group.fid" :value="group.fid">
                  {{group.fname}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            
            <a-form-model-item label="显示顺序">
              <a-input v-model="linkForm.fsid"/>
            </a-form-model-item>
            
            <a-form-model-item label="网站名">
              <a-input v-model="linkForm.fname"/>
            </a-form-model-item>
            
            <a-form-model-item label="私密">
              <a-switch v-model="linkForm.private"/>
            </a-form-model-item>
            
            <a-form-model-item label="链接">
              <a-input v-model="linkForm.flink"/>
            </a-form-model-item>
            
            <a-form-model-item label="Icon地址">
              <a-input v-model="linkForm.fico"/>
            </a-form-model-item>

            <a-form-model-item label="显示">
              <a-switch v-model="linkForm.fshowstatus"/>
            </a-form-model-item>
    
        </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import linksapi from '@/api/links.js'

export default {
  name: 'linksDiv',
  components: { },
  props: {
    pageHeight: {
      type: Number,
      required: true,
      default: 0
    },
    pageWidth: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      serviceStatus: false,
      linkGroups: [],
      linksPerLine: 2,
      editModal: false,
      editLinkModalShow: false,
      linkForm: {
        fgid: 1,
        fsid: '',
        fname: '',
        flink: '',
        fico: '',
        private: false,
        fshowstatus: false
      },
    }
  },
  created() {
    this.getLinks()
    this.$watch('$store.state.user.editStatus', this.editStatusChange)
  },
  mounted() {
    // console.log(this.pageWidth, this.pageHeight)
    this.updateCss()
  },
  updated() {
  },
  methods: {
    openEditModal(link) {
      console.log(link)
      this.editLinkModalShow = true
      this.linkForm = link
    },
    editStatusChange() {
      this.editModal = this.$store.state.user.editStatus
      console.log('编辑模式: ' ,this.editModal)
    },
    editLink() {
      console.log('提交link编辑')
      console.log(this.linkForm)
      let linkid = this.linkForm.fid

      // 提交链接信息
      linksapi.updateLink(linkid, this.linkForm
      ).then(res=>{
        console.log(res.data)
        let dictJson = res.data
        switch (dictJson.status) {
          case 0:
            // this.soul = res.data.data[0].fsouls
            // this.linkGroups = res.data.data.items
            this.handleCancel()
            this.getLinks()
            break
          default:
            this.$message.error(res.data.msg)
      }
      }).catch(err=>{
        this.$message.error(err+'')
      })
    },
    handleCancel() {
      this.editLinkModalShow = false
    },
    updateCss() {
      // 宽度处理
      if (this.pageWidth < 400) {
          this.linksPerLine = 2
      } else if (this.pageWidth >= 400 & this.pageWidth < 600) {
          this.linksPerLine = 3
      } else if (this.pageWidth >= 600 & this.pageWidth < 900) {
          this.linksPerLine = 6
      } else if (this.pageWidth > 1900) {
          this.linksPerLine = 8
      }

      // 高度处理
      if (this.pageHeight < 400) {
          document.getElementById('linksID').style.marginTop = '20px'
      } else if (this.pageHeight >= 400 & this.pageHeight < 600) {
          document.getElementById('linksID').style.marginTop = '20px'
      } else if (this.pageHeight >= 600 & this.pageHeight < 900) {
          document.getElementById('linksID').style.marginTop = '100px'
      } else if (this.pageHeight > 900) {
          document.getElementById('linksID').style.marginTop = '150px'
      }
    },
    openNewPage(url) {
      // console.log()
      if (this.editModal === true) {
        console.log('编辑模式，不跳转')
      } else {
        window.open(url)
      }
    },
    getLinks(args) {
        const _this = this;
        linksapi.GetLinks(args
        ).then(res=>{
          // console.log(res.data.data)
          _this.loading=true
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              // this.soul = res.data.data[0].fsouls
              this.linkGroups = res.data.data.items
              this.$emit('getLinksFormSon', this.linkGroups )
              _this.loading = true
              break
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    }
  },
  computed: {
  }
}
</script>
<style lang="less" scoped>
.linksdiv{
  width: 100%;
  background-color: #f8efea;
  align-items:center;
  padding: 18px 0px 36px 0px;
  border-radius:15px;
  /*margin-top: 100px;*/
}
.oneGroup{
    margin: 18px 0px 0px 18px;
}
.oneGroupLinks{
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-flow: wrap;
    float: left;
    align-items: center;/*垂直居中*/
    // justify-content: center;/*水平居中*/
    justify-content: left;/*水平居中*/
    margin: 0px 0px 0px 18px;
}
.oneLink {
    min-width: 120px;
    display:flex;
    align-items:center;/*垂直居中*/
    justify-content: left;/*水平居左*/
    margin: 0px 0px 8px 0px;
}
</style>
