import requests from '@/utils/requests'

//按照请求类型对axios进行封装
const api={
    get(url,data){
        return requests.get(url,{params:data})
    },
    delete(url){
        return requests.delete(url)
    },
    postBody(url,data){
        return requests.post(url,data)
    },
    putBody(url,data){
        return requests.put(url,data)
    },
}

export default api