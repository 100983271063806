import Vue from 'vue';
import Antd from 'ant-design-vue';
// import Button from 'ant-design-vue/lib/button';
import 'ant-design-vue/dist/antd.css';
import App from './App';
import store from './store/index'
import VueI18n from 'vue-i18n';
import cookie from 'js-cookie';

// import '@/components' // 引入自定义组件为全局组件

// Vue.component(Button.name, Button);
Vue.use(Antd);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = {
  locale: cookie.get('lang') || 'zh', // 语言标识,第一次登录默认是中文
  messages: {
    zh: require('@/locales/lang/zh-CN'), // 汉语
    en: require('@/locales/lang/en-US'), // 英语
  }
}

// new Vue({
//  store,
//  render: h => h(App),
// }).$mount('#app');

/* eslint-disable no-new */
new Vue({
    // router,
    i18n,
    store,
    render: h => h(App),
}).$mount('#app')