<template>
  <div class="souldiv">
    <a-row>
      <a-col :span="20">
        <div style="display:flex;float:left;align-items:center;">
          <a-button type="primary" @click="getNewSoul">
            再来一碗
          </a-button>
          <a class="soula" v-show="showSoul">
            {{soul}}
          </a>
        </div>
      </a-col>
      <a-col :span="4">
        <div style="display:flex;float:right;align-items:center;">
          
          <!--服务开关-->
          <a-switch @change="onChange" v-model="serviceStatus" style="margin: 0px 12px 0px 0px;"/>
          
          <!--ping延时Tag-->
          <a-tag color="green" v-if="(timedelayShow === true)"> {{timedelay}} </a-tag>
          <a-tag color="blue"  v-else> {{gettime}} </a-tag>
          
          <!--用户信息-->
          <Modals :groupsList="groupsList" @reloadLinks="reloadLinks"/>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import soulapi from '@/api/soul.js'
import pingApi from "@/api/ping.js"
import serviceSwitchapi from '@/api/serviceSwitch.js'
import Modals from "@/components/modals";

export default {
  name: 'pageHeader',
  components: { Modals },
  props: {
    groupsList: {
      type: Array,
      required: true,
      default: function () { return [] }
    },
    pageHeight: {
      type: Number,
      required: true,
      default: 0
    },
    pageWidth: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      soul: '',
      showSoul: true,
      serviceStatus: false,
      servicename: 'v2ray',
      timedelay: 0,
      gettime: 0,
      timedelayShow: true
    }
  },
  created() {
    this.getOneSoul()
    this.getOneSwitch({ service: this.servicename })
    // console.log(this.pageWidth, this.pageHeight)
  },
  mounted() {
    if (this.pageWidth < 600) {
      this.showSoul = false
    }
    this.getPing()
    var origin   = window.location.origin
    // this.webPing(origin)
    this.webPing(origin, this.handleResponse);
  },
  updated() {
  },
  methods: {
    reloadLinks() {
      this.$emit('reloadLinks')
    },
    onChange(checked) {
      // console.log(checked)
      this.updateOneSwitch({ service: this.servicename, status: checked })
    },
    getOneSwitch(args) {
        const _this = this;
        let status
        serviceSwitchapi.getOneSwitch(args
        ).then(res=>{
          // console.log(res.data.data)
          _this.loading=true
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              status = res.data.data.status
              if (status === 'disabled' | status === 'unactive') {
                this.serviceStatus = false
              } else if (status === 'active') {
                this.serviceStatus = true
              }
              _this.loading = true
              break
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    },
    updateOneSwitch(args) {
        console.log(args)
        const _this = this
        let status
        serviceSwitchapi.updateOneSwitch(args
        ).then(res=>{
          console.log(res)
          _this.loading=true
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              status = res.data.data.status
              if (status === 'disabled' | status === 'unactive') {
                this.serviceStatus = false
              } else if (status === 'active') {
                this.serviceStatus = true
              }
              _this.loading = true
              break
            default:
              console.log(res.data)
              if (args.status === true) {
                this.serviceStatus = false
              } else {
                this.serviceStatus = true
              }
              this.$message.error(res.data.msg)
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    },
    getNewSoul() {
      this.getOneSoul({ width: this.pageWidth, height: this.pageHeight })
    },
    getOneSoul(args) {
        const _this = this;
        soulapi.getOneSoul(args
        ).then(res=>{
          // console.log(res.data.data[0].fsouls)
          _this.loading=true
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              this.soul = res.data.data[0].fsouls
              _this.loading = true
              break
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    },
    handleResponse(response) {
      // console.log(response)
      this.gettime = response
      // 在这里可以对响应数据进行处理
    },
    webPing(host, callback) {
      var start = new Date().getTime();
      var xhr = new XMLHttpRequest();
      xhr.open('GET', host, true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          var end = new Date().getTime();
          var time = end - start;
          // this.gettime = time
          callback(time)
          // console.log(this.gettime)
        }
      };
      xhr.send();
    },
    getPing(args) {
        const _this = this;
        pingApi.getOnePing(args
        ).then(res=>{
          // console.log(res)
          _this.loading=true
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              this.timedelay = res.data.data.timedelay
              _this.loading = true
              break
            default:
              this.timedelayShow = false
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style lang="less" scoped>
.souldiv{
  width: 100%;
  height: 32px;
  background: rgba(0,0,0,.15);
  // display: flex;
}
.soula{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  /* 文本/Text-5 */
  color: #fff;
  margin: 0px 0px 0px 8px;
}
</style>
