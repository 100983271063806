import api from '@/api/api'

const user = {
    userinfo(params) {
        //console.log(params)
        return api.get("/api/v2/auth/userinfo",params);
    },
    login(body){
        return api.postBody("/api/v2/auth/login",body);
    },
    getuserinfo(params) {
        //console.log(params)
        return api.get("api/v1/user",params);
    },
}

export default user