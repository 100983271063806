<template>
  <div class="modalsdiv">
    <a-dropdown :trigger="trigger">
        <a-button type="primary" @click="openLoginModal">
          {{ loginButtonInfo }}      
        </a-button>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="openAddSoulModal">添加鸡汤</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="openAddLinkModal">添加链接</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="openUpdateImageModal">背景图</a>
          </a-menu-item>
          <a-menu-item>
            <a-switch checked-children="编辑模式" un-checked-children="编辑模式" @change="onChangeEditStatus"/>
          </a-menu-item>
          <a-menu-item>
            <a @click="Signout">注销</a>
          </a-menu-item>
        </a-menu>
    </a-dropdown>
    

    <!--登录模态框-->
    <a-modal
      :title="$t('button.login')"
      :visible="loginModalShow"
      @ok="handleOk"
      @cancel="handleCancel"
      >
        <a-form-model ref="loginRuleForm" :model="loginForm" :rules="loginRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item :label="$t('form.username')" ref="username"  prop="username">
              <a-input v-model="loginForm.username"/>
            </a-form-model-item>
            
            <a-form-model-item :label="$t('form.password')" ref="password"  prop="password">
              <a-input-password v-model="loginForm.password"/>
            </a-form-model-item>
        </a-form-model>
    </a-modal>

    <!--添加心灵鸡汤模态框-->
    <a-modal
      :title="$t('title1')"
      :visible="addSoulModalShow"
      @ok="addSoul"
      @cancel="handleCancel"
      >
        <a-form-model :model="soulForm" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="Soul">
              <a-input v-model="soulForm.soul"/>
            </a-form-model-item>
        </a-form-model>
    </a-modal>

    <!--添加链接模态框-->
    <a-modal
      :title="$t('title2')"
      :visible="addLinkModalShow"
      @ok="addLink"
      @cancel="handleCancel"
      >
        <a-form-model :model="linkForm" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="组名">
              <a-select default-value="lucy" style="width: 120px" v-model="linkForm.fgid">
                <a-select-option v-for="group in groupsList" :key="group.fid" :value="group.fid">
                  {{group.fname}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            
            <a-form-model-item label="显示顺序">
              <a-input v-model="linkForm.fsid"/>
            </a-form-model-item>
            
            <a-form-model-item label="网站名">
              <a-input v-model="linkForm.fname"/>
            </a-form-model-item>
            
            <a-form-model-item label="私密">
              <a-switch v-model="linkForm.private"/>
            </a-form-model-item>
            
            <a-form-model-item label="链接">
              <a-input v-model="linkForm.flink"/>
            </a-form-model-item>
            
            <a-form-model-item label="Icon地址">
              <a-input v-model="linkForm.fico"/>
            </a-form-model-item>

        </a-form-model>
    </a-modal>
        
    <!--修改背景图模态框-->
    <a-modal
      title="修改背景图"
      :visible="updateImageModalShow"
      @ok="updateImage"
      @cancel="handleCancel"
      >
        <a-form-model :model="imageForm" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="图片链接">
              <a-input v-model="imageForm.url"/>
            </a-form-model-item>
            <a-row>
                <a-col :span="14" :offset="6">
                    <a target="_blank" href="https://tupian.zol.com.cn/">
                        Zol图片
                    </a>
                </a-col>
            </a-row>
        </a-form-model>
    </a-modal>
    
  </div>
</template>

<script>
import userapi from '@/api/user.js'
import soulapi from '@/api/soul.js'
import linksapi from '@/api/links.js'
import backendapi from '@/api/backend.js'

import { getToken, setToken, removeToken, removeNickname } from '../utils/auth.js'

export default {
  name: 'modalsDiv',
  components: { },
  props: {
    groupsList: {
      type: Array,
      required: true,
      default: function () { return [] }
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      trigger: ['click', 'hover'],
      loginStatus: false,
      loginModalShow: false,
      updateImageModalShow: false,
      addLinkModalShow: false,
      addSoulModalShow: false,
      loginForm: {
        username: '',
        password: ''
      },
      soulForm: {
        soul: ''
      },
      imageForm: {
        url: ''
      },
      linkForm: {
        fgid: 1,
        fsid: '',
        fname: '',
        flink: '',
        fico: 'https://www.baidu.com/favicon.ico',
        private: false
      },
      linkGroups: [],
      loginRules: {
        username: [{ required: true, message: 'Please input Hours', trigger: 'blur' }],
        password: [{ required: true, message: 'Please input Hours', trigger: 'blur' }]
      },
      loginButtonInfo: this.$t('button.login')
    }
  },
  created() {
      var usertoken = getToken()
      if ( usertoken == undefined ) {
        console.log('未获取到token')
        this.nickname = '登陆'
      } else {
          // console.log(usertoken)
          this.userinfo(usertoken)
      }
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    onChangeEditStatus(checked) {
      // console.log('编辑模式')
      // console.log(checked)
      this.$store.dispatch('setEditStatus', checked)
    },
    openAddLinkModal() {
      console.log('打开添加链接模态框')
      this.addLinkModalShow = true
      // console.log(this.groupsList)
    },
    openLoginModal() {
      console.log('打开登录模态框')
      if (this.loginStatus === false) {
        this.loginModalShow = true
      }
    },
    openAddSoulModal() {
        console.log('添加心灵鸡汤')
        this.soulForm.soul = ''
        this.addSoulModalShow = true
    },
    openUpdateImageModal() {
        console.log('修改背景图')
        this.imageForm.url = ''
        this.updateImageModalShow = true
    },
    handleCancel() {
      this.loginModalShow = false
      this.addSoulModalShow = false
      this.addLinkModalShow = false
      this.updateImageModalShow = false
    },
    addLink() {
      linksapi.addLink(this.linkForm
      ).then(res=>{
        console.log(res)
        let dictJson = res.data
        switch (dictJson.status) {
          case 0:
            this.$emit('reloadLinks')
            this.handleCancel()
            break
          default:
            console.log(res.data)
            this.$message.error(res.data.msg)
      }
      }).catch(err=>{
        this.$message.error(err+'')
      })
    },
    updateImage() {
      backendapi.updateImage(this.imageForm
      ).then(res=>{
        console.log(res)
        let dictJson = res.data
        switch (dictJson.status) {
          case 0:
            // token = res.data.data.access_token
            // this.handleCancel()
            window.location.reload()
            break
          default:
            console.log(res.data)
            this.$message.error(res.data.msg)
      }
      }).catch(err=>{
        this.$message.error(err+'')
      })
    },
    addSoul() {
      soulapi.addSoul(this.soulForm
      ).then(res=>{
        // console.log(res)
        let dictJson = res.data
        switch (dictJson.status) {
          case 0:
            // token = res.data.data.access_token
            this.handleCancel()
            break
      }
      }).catch(err=>{
        this.$message.error(err+'')
      })
    },
    // 验证登录的表单
    handleOk() {
        // console.log(this.form)
        console.log('登录')
        this.$refs.loginRuleForm.validate(valid => {
          if (valid) {
              // console.log(this.loginForm)
              this.login(this.loginForm)
          } else {
            this.$message.error('提交失败')
            return false
          }
        })
    },
    login(form) {
        let token
        userapi.login(form
        ).then(res=>{
          // console.log(res)
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              token = res.data.data.access_token
              // console.log(token)
              // this.$store.dispatch('setToken', token)
              setToken(token)
              this.userinfo(token)
              this.handleCancel()
              this.$emit('reloadLinks')
              break
            default:
              this.$message.error('登录失败')
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    },
    userinfo(token) {
        let userinfo
        userapi.userinfo({access_token: token}
        ).then(res=>{
          // console.log(res)
          let dictJson = res.data
          switch (dictJson.status) {
            case 0:
              userinfo = res.data.data
              // console.log(userinfo)
              // setNickname(userinfo.fnickname)
              this.$store.dispatch('setUserinfo', userinfo)
              this.loginButtonInfo = userinfo.fnickname
              this.loginStatus = true
              break
        }
        }).catch(err=>{
          this.$message.error(err+'')
        })
    },
    Signout() {
        removeToken()
        removeNickname()
        this.$store.dispatch('removeUserinfo')
        this.loginStatus = false
        window.location.reload()
    }
  },
  computed: {
  },
  watch: {
      '$cookies.get'() {
        let cookieValue = this.$cookies.get('editModal')
        console.log(cookieValue)
      }
  }
}
</script>
<style lang="less" scoped>
.linksdiv{
  width: 100%;
  // height: 32px;
  // background-color: bisque;
  background-color: #f8efea;
  // background: hsla(0,0%,100%,.85);
  // background-color: rgba(255, 255, 255, 0.85);
  // background-color: 'bisque' !important;
  align-items:center;
  padding: 18px 0px 36px 0px;
  border-radius:15px;
}
.oneGroup{
    margin: 18px 0px 0px 18px;
}
.oneGroupLinks{
    width: 100%;
    // height: 100%;
    display:flex;
    float:left;
    align-items:center;/*垂直居中*/
    // justify-content: center;/*水平居中*/
    justify-content: left;/*水平居中*/
    
}
.oneLink {
    width: 120px;
    display:flex;
    align-items:center;/*垂直居中*/
    justify-content: left;/*水平居左*/
}
</style>
