<template>
  <div>
    <DaoHang/>
  </div>
</template>

<script>
import DaoHang from './view/index.vue'

export default {
  name: 'App',
  components: {
    DaoHang
  },
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {}
}
</script>

<style>
</style>
