const user = {
  state: {
    nickname: '',
    avatar: '',
    username: '',
    editStatus: false
  },

  mutations: {
    SET_NAME: (state, username, nickname) => {
      state.username = username
      state.nickname = nickname
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_EDITSTATUS: (state, status) => {
      state.editStatus = status
    }
  },
  actions: {
    setUserinfo({ commit }, userinfo) {
      return new Promise(() => {
        commit('SET_NAME', userinfo.username, userinfo.fnickname)
        commit('SET_AVATAR', userinfo.favatar)
      })
    },
    removeUserinfo({ commit }) {
      return new Promise(() => {
        commit('SET_NAME', '')
        commit('SET_AVATAR', '')
        commit('SET_TOKEN', '')
      })
    },
    setEditStatus({ commit }, editStatus) {
      return new Promise(() => {
        commit('SET_EDITSTATUS', editStatus)
      })
    }
  }
}

export default user
