import api from '@/api/api'

const serviceSwitch = {
    getOneSwitch(params) {
        //console.log(params)
        return api.get("/api/v2/switch", params);
    },
    updateOneSwitch(params) {
        return api.putBody("/api/v2/switch", params);
    }
}

export default serviceSwitch