const img_path = '/static/icos/'

const searchList = {
    baidu: {
        title: '百度',
        url: 'https://www.baidu.com/s',
        key: 'wd',
        icon: img_path + 'icon_baidu.png'
    },
    iconfont: {
        title: 'iconfont',
        url: 'https://www.iconfont.cn/search/index',
        key: 'q',
        icon: img_path + 'iconfont.svg'
    },
    bing: {
        title: '必应',
        url: 'https://cn.bing.com/search',
        key: 'q',
        icon: img_path + 'icon_bing.png'
    },
    so: {
        title: '360搜索',
        url: 'https://www.so.com/s',
        key: 'q',
        icon: img_path + 'icon_360.png'
    },
    google: {
        title: 'Google',
        url: 'https://www.google.com/search',
        key: 'q',
        icon: img_path + 'icon_google.png'
    }
}

const linkList = {
    code: [
        {
            title: 'GitHub',
            url: 'https://github.com/',
            icon: img_path + 'github.png'
        },
        {
            title: 'Gitee',
            url: 'https://gitee.com/',
            icon: img_path + 'gitee.ico'
        },
        {
            title: '阿里云',
            url: 'https://www.aliyun.com',
            icon: img_path + 'aliyun.ico'
        },
        {
            title: '腾讯云',
            url: 'https://cloud.tencent.com',
            icon: img_path + 'qcloud.png'
        }
    ],
    video: [
        {
            title: '腾讯视频',
            url: 'https://v.qq.com/',
            icon: img_path + 'vqq.png'
        },
        {
            title: '爱奇艺',
            url: 'https://www.iqiyi.com/',
            icon: img_path + 'iqiyi.png'
        },
        {
            title: '优酷',
            url: 'https://www.youku.com/',
            icon: img_path + 'youku.png'
        },
        {
            title: 'Bilibili',
            url: 'https://www.bilibili.com/',
            icon: img_path + 'bilibili.png'
        },
        {
            title: '944',
            url: 'https://www.944TV.com/',
            icon: img_path + '944.ico'
        },
        {
            title: '虎牙直播',
            url: 'https://www.huya.com/l',
            icon: img_path + 'huya.png'
        },
    ],
    videodownload: [
        {
            title: '豆瓣',
            url: 'https://www.douban.com/',
            icon: img_path + 'douban.ico'
        },
        {
            title: '电影天堂',
            url: 'https://www.dy2018.com/',
            icon: img_path + 'dy2018.ico'
        },
        {
            title: '6V',
            url: 'https://www.hao6v.tv/',
            icon: img_path + '6v.ico'
        },
    ],
    company: [
        {
          title: '华为',
          url: 'https://www.vmall.com/',
          icon: img_path + 'huawei.png'
        },
        {
          title: '荣耀',
          url: 'https://www.hihonor.com/cn/shop/',
          icon: img_path + 'honor.ico'
        },
        {
            title: '苹果',
            url: 'https://www.apple.com.cn/',
            icon: img_path + 'apple.ico'
        },
        {
            title: '小米',
            url: 'https://mi.com/',
            icon: img_path + 'mi.ico'
        }
    ],
    kc: [
        {
            title: '知乎',
            url: 'https://www.zhihu.com/',
            icon: img_path + 'zhihu.ico'
        },
        {
            title: '掘金',
            url: 'https://juejin.cn/',
            icon: img_path + 'juejin.ico'
        }
    ],
}

export default {
    searchList,
    linkList,
}
