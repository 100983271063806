import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user.js'

import mutations from './mutations'
import Data from '../config/utils'
import SoulData from '../config/soul'

Vue.use(Vuex)

const state = {
    searchList: Data.searchList,
    linkList: Data.linkList,
    soulList: SoulData.souls,
    groupeditbutton: false,
    lang: 'zh-CN'
}

const getters = {
    searchTypes(state) {
        return Object.keys(state.searchList)
    },
    typelinks: function (state) {
      // 返回一个函数用于接收
      return function (articleId) {
        // console.log(articleId)
        var _list = []
        var _dic = state.linkList
        _list = _dic[articleId].slice()
        // var type=Object.prototype.toString
        // console.log('类型？' + typeof _list)
        // console.log(Object.prototype.toString.call(_list))
        return _list
      }
    }
}

export default new Vuex.Store({
    modules: {
      user
    },
    state,
    getters,
    mutations
})
