<template>
  <div id="app" class="indexPage">

    <!--页面header(心灵鸡汤+用户登录)-->
    <Soul :pageHeight="pageHeight" :pageWidth="pageWidth" :groupsList="groupsList" @reloadLinks="reloadLinks"/>

    <!--搜索框-->
    <a-row type="flex" class="row-bg" justify="center">
      <a-col :xs="24" :sm="16" :md="12" :lg="8" id="col-search">
        <Search :pageHeight="pageHeight" :pageWidth="pageWidth"/>
      </a-col>
    </a-row>
    
    <!--链接框-->
    <a-row type="flex" class="row-bg" justify="center">
      <a-col :xs="24" :sm="24" :md="20" :lg="20" :xl="16" :xxl="16" id="col-links">
        <Links ref="links" @getLinksFormSon="getLinksFormSon" :pageHeight="pageHeight" :pageWidth="pageWidth"/>
      </a-col>
    </a-row>        

  </div>
</template>

<script>
import Soul from '@/components/soul.vue'
import Search from "@/components/search"
import Links from "@/components/links"

export default {
  name: 'DaoHang',
  components: {
      Soul, Search, Links
  },
  data() {
    return {
      groupsList: [],
      addnewtitle:'',
      pageHeight: 0,
      pageWidth: 0,
      imageUrl: '',
      responseTime: 0,
      timedelay: 0,
      gettime: 0
    };
  },
  computed: { 
  },
  mounted() {
  },
  created() {
    this.pageHeight = document.documentElement.clientHeight
    this.pageWidth = document.documentElement.clientWidth
  },
  methods: {
    getLinksFormSon(list) {
      this.groupsList = list
    },
    reloadLinks() {
      this.$refs.links.getLinks()
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.indexPage{
    /*background-color: black;*/
    width: 100%;
    /*height: 100%;*/
    min-height: 100vh;
    /*background-image:  url(http://dev.chrisli.top/api/v2/backendimage);*/
    background-image:  url(https://www.chrisli.top/api/v2/backendimage);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size:100% 100%;
    /*position: fixed;*/
}
.testpic{
    width: 100px;
    height: 100px;
    
    /*background-color: black;*/
    /*background-image: url(/api/v2/backendimage);*/
}
</style>
